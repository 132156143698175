<template>
  <div>
    <ul>
      <li v-for="m in selectedModules" :key="m?.uid">
        {{ m?.libelle }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    object: { type: Object, required: true }
  },
  computed: {
    ...mapGetters({
      modules: 'packs/modules',
    }),
    selectedModules() {
      const modulesArray = JSON.parse(this.object.modules);
      return modulesArray.map(moduleId => this.modules.find(item => item.uid === moduleId));
    },
  }
};
</script>